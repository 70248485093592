import { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { convertCompilerOptionsFromJson } from "typescript";
import { AppContext } from "./AppContext";
import {
  SsoAppsData,
  SsoAppUsers,
  SsoUser,
  SsoUserAppAddBody,
  SsoUserAppAddHrBody,
  SsoUserAppEntitiesData,
} from "./masterbigsystem";

const MainComponent = () => {
  const ctx = useContext(AppContext);
  // const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<SsoAppsData | null | undefined>(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [searchByNameFilter, setSearchByNameFilter] = useState("");
  const [selectedApp, setSelectedApp] = useState(
    null as SsoAppUsers | null | undefined
  );
  const [ssoUserAppEntitiesData, setSsoUserAppEntitiesData] = useState<
    SsoUserAppEntitiesData | null | undefined
  >(null);
  const [selectedHrUser, setSelectedHrUser] = useState(
    null as SsoUser | null | undefined
  );
  const [searchUser, setSearchUser] = useState("");
  const searchUserRef = useRef(null);
  const [loginPassphrase, setLoginPassphrase] = useState("");

  useEffect(() => {
    handleInit();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users`);

      if (res.status !== 200) throw await res.text();

      return (await res.json()) as SsoAppsData | null | undefined;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  const fetchSsoAppUsers = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/sso-app-users`
      );

      if (res.status !== 200) throw await res.text();

      return (await res.json()) as SsoUserAppEntitiesData | null | undefined;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  const handleInit = async () => {
    try {
      setLoading(true);

      const [users, ssoUserAppEntitiesDataRes] = await Promise.all([
        fetchUsers(),
        fetchSsoAppUsers(),
      ]);

      setUsers(users);
      setSsoUserAppEntitiesData(ssoUserAppEntitiesDataRes);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* {ctx.apiKey} */}
      {!ctx.apiKey ? (
        <>
          <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
            <div className="d-flex flex-column align-items-center p-5 border border-secondary shadow shadow-lg">
              <div>
                <h5>GSPE Single Sign On (SSO)</h5>
              </div>
              <div>
                <input
                  className="form-control form-control-sm"
                  placeholder="Passphrase..."
                  onBlur={(e) => {
                    setLoginPassphrase(e.target.value);
                  }}
                />
              </div>
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={async () => {
                    try {
                      const resp = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/login`,
                        {
                          method: "post",
                          headers: {
                            "content-type": "application/json",
                          },
                          body: JSON.stringify({ passphrase: loginPassphrase }),
                        }
                      );

                      if (resp.status !== 200) throw await resp.text();

                      const tok = (await resp.json()) as {
                        token?: string | null;
                      };

                      if (tok.token) {
                        ctx.setApiKey(tok.token);
                        localStorage.setItem("apiKey", tok.token);
                      }
                    } catch (e) {}
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Container>
            <div className="d-flex align-items-center">
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    ctx.setApiKey(null);
                    localStorage.removeItem("apiKey");
                  }}
                >
                  Logout
                </button>
              </div>
              {loading ? (
                <div className="mx-2">
                  Loading... <Spinner size="sm" animation="border" />
                </div>
              ) : (
                <></>
              )}
              <form
                className="flex-grow-1"
                onSubmit={(e) => {
                  e.preventDefault();

                  document.getElementById("search-by-name-filter")?.blur();
                }}
              >
                <div>
                  <input
                    onBlur={(e) => {
                      setSearchByNameFilter(e.target?.value);
                    }}
                    id="search-by-name-filter"
                    className="form-control"
                    placeholder="Search by name..."
                  />
                </div>
              </form>
            </div>
            <div
              className="overflow-auto border-secondary border shadow shadow-md"
              style={{ height: "85vh", resize: "vertical" }}
            >
              <table
                style={{ borderCollapse: "separate" }}
                className="table table-sm table-bordered table-striped table-hover"
              >
                <tr>
                  {[
                    "#",
                    "HR detail",
                    "SSO Exists",
                    "SSO Username",
                    "SSO Password",
                  ].map((h) => (
                    <th
                      className="sticky-top top-0 border bg-dark text-light align-center text-center"
                      style={{ border: "2px solid white" }}
                    >
                      {h}
                    </th>
                  ))}

                  {users?.app
                    .filter((a) => a.appName?.toLowerCase() !== "hr")
                    .map((a) => {
                      return (
                        <th className="sticky-top top-0 border bg-dark text-light align-center text-center">
                          {a.appName}
                        </th>
                      );
                    })}
                </tr>
                {(() => {
                  const filteredUsers = users?.app
                    ?.find((a) => a.appName?.toLowerCase() === "hr")
                    ?.users?.filter((u) =>
                      u?.name
                        ?.toLowerCase()
                        ?.includes(searchByNameFilter?.toLowerCase())
                    );
                  return (
                    <>
                      {filteredUsers?.map((u, i) => {
                        const foundSsoUserApp =
                          ssoUserAppEntitiesData?.ssoUserAppEntities?.find(
                            (e) =>
                              e?.ssoAppEntity?.name === "hr" &&
                              e?.extUserId === u.id &&
                              e.ssoUserEntity?.gormModel?.id !== null &&
                              e.ssoUserEntity?.gormModel?.id !== undefined &&
                              e.ssoUserEntity?.gormModel?.id !== "0"
                          );

                        return (
                          <tr>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              <div>
                                <div>{u.name}</div>
                                <div className="fw-bold">
                                  <small>Username: {u.username}</small>
                                </div>
                                <div className="fw-bold">
                                  <small>ID: {u.id}</small>
                                </div>
                              </div>
                            </td>
                            {(() => {
                              return (
                                <td
                                  className={`${
                                    foundSsoUserApp ? `bg-success` : `bg-danger`
                                  }`}
                                >
                                  <div>
                                    {foundSsoUserApp ? (
                                      <div className="fw-bold text-light">
                                        Yes
                                      </div>
                                    ) : (
                                      <>
                                        <button
                                          onClick={async () => {
                                            const confirmed = window.confirm(
                                              `Create SSO User for ${u.name}?`
                                            );

                                            if (confirmed) {
                                              const resp = await fetch(
                                                `${process.env.REACT_APP_BASE_URL}/sso-user-add-hr`,
                                                {
                                                  method: "post",
                                                  headers: {
                                                    "content-type":
                                                      "application/json",
                                                  },
                                                  body: JSON.stringify({
                                                    extUserId: u.id,
                                                  } as SsoUserAppAddHrBody),
                                                }
                                              );

                                              await handleInit();
                                            }
                                          }}
                                          className="btn btn-secondary btn-sm"
                                        >
                                          Create
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </td>
                              );
                            })()}

                            <td className="bg-danger">
                              <div></div>
                            </td>
                            <td className="bg-danger">
                              <div></div>
                            </td>

                            {users?.app
                              .filter((a) => a.appName?.toLowerCase() !== "hr")
                              .map((a) => {
                                const foundSsoUserAppX =
                                  ssoUserAppEntitiesData?.ssoUserAppEntities?.find(
                                    (e) =>
                                      e?.ssoAppEntity?.name === a.appName &&
                                      e?.ssoUserEntity?.gormModel?.id ===
                                        foundSsoUserApp?.ssoUserEntityId &&
                                      e.ssoUserEntity?.gormModel?.id !== null &&
                                      e.ssoUserEntity?.gormModel?.id !==
                                        undefined &&
                                      e.ssoUserEntity?.gormModel?.id !== "0"
                                  );

                                const foundExtUser = users.app
                                  ?.find((ax) => ax.appName === a?.appName)
                                  ?.users?.find(
                                    (u) => u?.id === foundSsoUserAppX?.extUserId
                                  );

                                return (
                                  <>
                                    {/* If sso does not exist, just disable mapping */}

                                    <td
                                      className={
                                        foundSsoUserAppX
                                          ? "bg-success"
                                          : "bg-danger"
                                      }
                                    >
                                      {foundSsoUserApp ? (
                                        <>
                                          {foundSsoUserAppX ? (
                                            <>
                                              <div>
                                                <div className="d-flex">
                                                  <div className="text-light fw-bold">
                                                    OK
                                                  </div>
                                                  <div className="mx-2">
                                                    <button
                                                      className="btn btn-danger btn-sm"
                                                      onClick={async (e) => {
                                                        if (
                                                          window.confirm(
                                                            `Really undo ${foundExtUser?.name} (${foundSsoUserAppX?.ssoAppEntity?.name})?`
                                                          )
                                                        ) {
                                                          const resp =
                                                            await fetch(
                                                              `${process.env.REACT_APP_BASE_URL}/sso-app-users/${foundSsoUserAppX?.gormModel?.id}`,
                                                              {
                                                                method:
                                                                  "delete",
                                                                headers: {
                                                                  "content-type":
                                                                    "application/json",
                                                                },
                                                              }
                                                            );
                                                          handleInit();
                                                        }
                                                      }}
                                                    >
                                                      Undo
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="text-light fw-bold">
                                                  ID:{" "}
                                                  {foundSsoUserAppX?.extUserId}
                                                </div>
                                                <div className="text-light">
                                                  {(() => {
                                                    return (
                                                      <>
                                                        {foundExtUser?.name} (
                                                        {
                                                          foundExtUser
                                                            ?.department?.name
                                                        }
                                                        )
                                                      </>
                                                    );
                                                  })()}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="d-flex align-items-center">
                                                <div className="fw-bold text-light">
                                                  Not Selected
                                                </div>
                                                <div>
                                                  <button
                                                    onClick={() => {
                                                      setUserModalOpen(true);
                                                      setSelectedApp(a);
                                                      setSelectedHrUser(u);
                                                    }}
                                                    className="btn btn-secondary btn-sm"
                                                  >
                                                    Select {a.appName} mapping
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                          </tr>
                        );
                      })}
                    </>
                  );
                })()}
              </table>
            </div>
          </Container>
        </>
      )}

      <Modal
        size="xl"
        show={userModalOpen}
        onHide={() => {
          setUserModalOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              Select {selectedApp?.appName} mapping for {selectedHrUser?.name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Select User:</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // (searchUserRef?.current as any)?.blur();

              // Imperative blur
              document.getElementById("myselect")?.blur();
            }}
          >
            <div className="d-flex align-items-center">
              <input
                className="form-control form-control-sm"
                placeholder="Search User by name, username..."
                ref={searchUserRef}
                id="myselect"
                onBlur={(e) => {
                  setSearchUser(e.target.value);
                }}
              ></input>
              <button type="submit" className="btn btn-secondary btn-sm">
                Search
              </button>
            </div>
          </form>

          <div className="my-2 overflow-auto" style={{ height: "60vh" }}>
            <table
              className="table table-striped table-bordered table-hover"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["Select", "#", "Name", "Username", "Department", "Roles"].map(
                  (h) => (
                    <th className="sticky-top top-0 bg-dark text-light">{h}</th>
                  )
                )}
              </tr>
              {users?.app
                .find((a) => a.appName === selectedApp?.appName)
                ?.users?.filter((u) =>
                  `${u.name}${u.username}${u?.department?.name}${u?.roles?.map(
                    (r) => r.name
                  )}`
                    .toLowerCase()
                    .includes(searchUser)
                )
                .map((u, i) => {
                  return (
                    <tr>
                      <td className="border border-dark">
                        <div>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={async () => {
                              const foundSsoUserApp =
                                ssoUserAppEntitiesData?.ssoUserAppEntities?.find(
                                  (e) =>
                                    e?.ssoAppEntity?.name === "hr" &&
                                    e?.extUserId === selectedHrUser?.id &&
                                    e.ssoUserEntity?.gormModel?.id !== null &&
                                    e.ssoUserEntity?.gormModel?.id !==
                                      undefined &&
                                    e.ssoUserEntity?.gormModel?.id !== "0"
                                );

                              if (
                                window.confirm(
                                  `Really map HR user ${selectedHrUser?.name} to ${u?.name} (${selectedApp?.appName})?`
                                )
                              ) {
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/sso-user-app-add`,
                                  {
                                    method: "post",
                                    headers: {
                                      "content-type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      extUserId: u?.id,
                                      appName: selectedApp?.appName,
                                      ssoUserId:
                                        foundSsoUserApp?.ssoUserEntity
                                          ?.gormModel?.id,
                                    } as SsoUserAppAddBody),
                                  }
                                );

                                setUserModalOpen(false);
                                handleInit();
                              }
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </td>
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">{u.name}</td>
                      <td className="border border-dark">{u.username}</td>
                      <td className="border border-dark">
                        {u?.department?.name}
                      </td>
                      <td className="border border-dark">
                        {u?.roles?.map((r) => r?.name)?.join(", ")}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainComponent;
