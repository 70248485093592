import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import MainComponent from "./MainComponent";
import { AppContext } from "./AppContext";

function App() {
  const [apiKey, setApiKey] = useState(
    localStorage.getItem("apiKey") as string | null | undefined
  );

  return (
    <div>
      <AppContext.Provider
        value={{
          apiKey: apiKey,
          setApiKey: (s: string | null | undefined) => setApiKey(s),
        }}
      >
        <MainComponent />
      </AppContext.Provider>
    </div>
  );
}

export default App;
